@tailwind base;
@tailwind components;
@tailwind utilities;

.nice-dates-day {
  color: white !important;
}

.-outside {
  cursor: none;
  color: lightgray;
  visibility: hidden;
}

.-outside:hover {
  cursor: none;
  border: none;
}

.nice-dates-navigation_previous,
.nice-dates-navigation_next {
  visibility: visible;
}

.nice-dates-navigation_current {
  color: white;
  font-size: 20px;
}
.-selected:hover {
  color: #d42234 !important;
  background-color: none !important;
}

span.nice-dates-day.-selected:before {
  background-color: #fff;
}

span.nice-dates-day.-selected span {
  color: #d42234 !important;
}

.nice-dates-week-header_day {
  color: white !important;
}

.nice-dates-day:hover span {
  color: #d42234 !important;
}
