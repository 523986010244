@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;500;600;700&display=swap");

:root {
  --scroll: none;
  --opacity: 1;
}

body {
  font-family: Futura;
  overflow: var(--scroll);
  /* opacity: var(--opacity); */
}

.terms {
  box-shadow: 0px 0px 560px 740px rgba(0, 0, 0, 0.5);
}

.botton_hover:hover .btn_move,
.btn_move:hover {
  transition: all;
  transition-duration: 0.25s;
  transition-timing-function: linear;
  padding-left: 40px;
}
.banner_background {
  background: linear-gradient(to right, #a0a3a5 0%, #a0a3a5 15%, #fff 10%);
}
.banner_dot_list {
  right: 50%;
  bottom: 20px;
}
.latest_dot_list {
  bottom: 20px;
}
.news_background {
  background: linear-gradient(to right, #fff 0%, #fff 7%, #231f1f 7%);
}
.news_bg {
  background: #231f1f;
}
.react-multi-carousel-dot button {
  border: none;
  background: #d5d5d5;
}
.react-multi-carousel-dot--active button {
  background: #a0a3a5;
  border: none;
}
.icon_hidden {
  display: none;
}
.icon_unhidden:hover .icon_hidden {
  display: flex;
}
.theme-red {
  color: #d42234 !important;
}
.hr_dot_list {
  left: 50%;
  bottom: 30px;
}

.hr_background {
  background: linear-gradient(
    to bottom,
    #000000 0%,
    #000000 50%,
    #d42234 50%,
    #d42234 100%
  );
}
.profile_background {
  background: linear-gradient(
    to bottom,
    #fff 0%,
    #fff 30%,
    #dfe4ea 30%,
    #dfe4ea 100%
  );
}
.news_hover:hover .news_content {
  display: none;
}
.news_content {
  background-color: rgba(0, 0, 0, 0.6);
}
.news_hover:hover .hovered_class {
  top: 0px;
  transition-duration: 700ms;
  background-color: rgba(0, 0, 0, 0.7);
}
.birth_day .img_class {
  transition-duration: 700ms;
}
.birth_day:hover .img_class {
  transform: scale(1.1);
  transition-duration: 700ms;
}
@font-face {
  font-family: Futura;
  src: url(../fonts/Futura-Book-font.ttf);
}

@font-face {
  font-family: Futura-Medium;
  src: url(../fonts/futura-medium-bt.ttf);
}

@font-face {
  font-family: Futura-Light;
  src: url(../fonts/futura-light-bt.ttf);
}

p {
  font-size: 1.1rem;
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 1.8rem;
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: black;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

.article_hover:hover .article_head {
  color: #d42234;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb {
  background-color: #335ddc;
  border-radius: 8px;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #d9edff;
}
.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
  align-items: flex-start;
}
#login-form {
  flex: 1 0 100%;
  max-width: 428px;
  width: 100%;
  padding: 60px;
}
#login-form p {
  margin-bottom: 30px;
}
#login-form h1.form-title {
  color: #333333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
}
#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
input {
  text-align: left;
}
#login-form input {
  padding: 12px 15px;
}
#login-form_username {
  height: 48px;
  width: 100%;
  margin-bottom: 24px;
  border-width: 1px !important;
}
#login-form button {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
  color: #fff;
}
.login-form-button {
  width: 100%;
  background-color: cornflowerblue;
}
.illustration-wrapper {
  display: flex;
  align-items: center !important;
  max-width: 800px;
  min-height: 100%;
  background-color: #ffff;
  width: 500px;
  height: 30rem;
  justify-content: center !important;
}
.illustration-wrapper img {
  display: block;
  width: 50%;
}
.checkbox {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 24px;
}

.otpform > input {
  width: 50px;
  border-bottom-width: 2px;
  margin-bottom: 50px;
}
.otpform {
  display: flex;
  justify-content: space-between;
}
#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  outline: none;
}

.pg-viewer-wrapper {
  overflow: hidden;
}

.pdf-viewer {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

#signup {
  color: #335ddc;
  cursor: pointer;
}

#signup:hover {
  text-decoration: underline;
}

@media screen and (max-width: 690px) {
  .illustration-wrapper img {
    display: block;
    width: 50%;
  }
  #rightSide {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #login-form {
    max-width: 100%;
    padding: 30px;
  }
  #rightSide {
    display: none;
  }
}
.comment-btn::before {
  content: "";
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  border: 1px solid #fff;
  opacity: 0;
}
.comment-btn:hover::before {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.new,
.hr-new {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
}
.new-clip {
  /* clip-path: polygon(38% 0, 100% 58%, 100% 0); */
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-right: 50px solid #ce0f14;
  border-top: 50px solid #ce0f14;
  top: -10px;
  right: -10px;
}

.hr-new-clip {
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-right: 50px solid #ce0f14;
  border-top: 50px solid #ce0f14;
  top: -10px;
  right: 18px;
}

.new::before {
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 10px solid #ce0f14;
  border-bottom: 0px solid #ce0f14;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  top: -10px;
  left: -10px;
  border-radius: 2px;
}

.new::after {
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 10px solid #ce0f14;
  border-bottom: 0px solid #ce0f14;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  bottom: -10px;
  right: -20px;
  border-radius: 2px;
}

@media screen and (min-width: 300px) and (max-width: 640px) {
  .hr_dot_list {
    bottom: 6px;
  }
}
input[type="file"]::file-selector-button {
  border: 2px solid #d42234;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #fff;
  transition: 1s;
}

input[type="file"]::file-selector-button:hover {
  background-color: #d42234;
  border: 2px solid #d42234;
  color: #fff;
}

.react-multiple-carousel__arrow:hover {
  background: none !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(-1% + 5px);
}

.react-multiple-carousel__arrow--left {
  left: calc(-1% + 15px);
}
.react-multiple-carousel__arrow--left::before,
.react-multiple-carousel__arrow--right::before {
  content: "" !important;
  background-size: inherit !important;
  width: 23px !important;
  height: 40px !important;
  background-repeat: no-repeat !important;
}
.react-multiple-carousel__arrow--left::before {
  background-image: url(../media/arrow_left.svg);
}
.react-multiple-carousel__arrow--right::before {
  background-image: url(../media/arrow_right.svg);
}
.navbefore::before {
  content: "";
  position: absolute;
  top: -9px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
}

.bidding-scroll::-webkit-scrollbar {
  display: none;
}

.bidding-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.recent-events .react-multiple-carousel__arrow--right {
  right: calc(1% + 10px) !important;
}

.recent-events .react-multiple-carousel__arrow--left {
  left: calc(1% + 10px) !important;
}

.recent-events .react-multiple-carousel__arrow::before {
  color: #fff !important;
}
.recent-events .react-multiple-carousel__arrow--right::before,
.recent-events .react-multiple-carousel__arrow--left::before {
  content: "";
  background-size: inherit;
  width: 23px;
  height: 40px;
  background-repeat: no-repeat;
}
.recent-events .react-multiple-carousel__arrow--left::before {
  background-image: url(../media/arrow_white_left.svg);
}
.recent-events .react-multiple-carousel__arrow--right::before {
  background-image: url(../media/arrow_white_right.svg);
}
.single-side canvas {
  margin: 0 auto;
  width: 712px !important;
  height: 892px !important;
}
.double-side canvas {
  margin: 0 auto !important;
}
